<template>
  <div>
    <v-data-table
      loading-text="Loading"
      :headers="headers"
      height="inherit"
      :items="pools"
      :loading="loading"
      no-data-text="Please Create A Pool"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <!-- BackfillPoolsTable.vue -->
      <!-- Table Rows Custom Format -->
      <template v-slot:item.name="props">
        <div
          :class="{ pointer: !readOnlyPools }"
          @click="
            setEditPool(true);
            editItem(props.item);
          "
        >
          {{ props.item.name }}
        </div>
      </template>

      <template v-slot:item.percentage="props">
        <div :class="{ disableSlider: !props.item.enabled }">
          <v-row>
            <v-col cols="10" class="pt-0 pb-0 mb-2 mt-2">
              <v-slider
                :class="{ pointer: !readOnlyPools }"
                @click.native="
                  setEditPercent(true);
                  readOnlyPools ? '' : editItem(props.item);
                "
                readonly
                :thumb-size="20"
                :thumb-color="!props.item.enabled ? 'rgba(25, 118, 210, .8)' : 'rgb(25, 118, 210)'"
                thumb-label="always"
                @change="calcTotalPercentage()"
                v-model="props.item.percentage"
              ></v-slider>
            </v-col>
            <v-col cols="2" class="pt-0 pb-0 mt-2">
              <v-icon
                size="20"
                :class="{ pointer: !readOnlyPools }"
                @click.native="
                  props.item.locked = !props.item.locked;
                  reCalculateLockedPercent(props.item);
                "
                :disabled="readOnlyPools"
              >
                {{ props.item.locked ? "mdi-lock" : "mdi-lock-open-outline" }}
              </v-icon>
            </v-col>
          </v-row>
        </div>
      </template>
      <!-- This is always set to readonly bc it's handled manually -->
      <template v-slot:item.enabled="{ item }">
        <v-switch
          inset
          dense
          @click.prevent.native="item.enabled ? disablePool(item) : enablePool(item)"
          readonly
          flat
          v-model="item.enabled"
          class="mt-2"
        ></v-switch>
      </template>

      <template v-slot:item.items="props">
        <div
          :class="{ pointer: !readOnlyPools }"
          @click="
            setEditPool(true);
            editItem(props.item);
          "
        >
          {{ props.item.items }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          :class="{ pointer: !readOnlyPools }"
          class="mr-2"
          @click.native="
            setEditPool(true);
            editItem(item);
          "
          :readonly="readOnlyPools"
        >
          mdi-pencil
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary">No Data</v-btn>
      </template>
      <!-- End of Table Rows -->

      <!-- EditBackfillPool.vue -->
      <template v-slot:top>
        <app-backfill-pools-edit-slot
          v-if="!spotId"
          @poolSaved="saveDialog"
          @reCalculate="calcTotalPercentage"
          :feedId="feedId"
          :isValid="isValid"
        ></app-backfill-pools-edit-slot>
      </template>
      <!-- End of EditBackfillPool.vue -->
    </v-data-table>
    <div v-if="!loading && !spotId">
      <v-row class="ml-0 mr-0 mb-0 mt-0">
        <v-col cols="6" class="totalPercentage pt-1 pb-1">
          <v-row align="center" justify="start" class="ml-0 mr-0 mb-0 mt-0">
            <h5 v-if="pools.length > 0" class="ml-5 mt-1">Total Percent: {{ totalPercentage }}%</h5>
          </v-row>
        </v-col>
        <v-col cols="6" class="userHistory pt-1 pb-1">
          <v-row align="center" justify="end" class="ml-0 mr-0 mb-0 mt-0">
            <v-col cols="7" class="pt-0 pb-0">
              <h5 v-if="pools.length > 0 && lastEditedBy" class="pt-0 pb-0">
                Last Edited By: {{ lastEditedBy.username }} | {{ timestamp }}
              </h5>
            </v-col>
            <v-col v-if="pools.length > 0" cols="4" class="pt-0 pb-0 mt-1">
              <app-backfill-pools-history-log></app-backfill-pools-history-log>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-alert
        v-if="pools.length > 0 && totalPercentage !== 100"
        class="mb-0 font-weight-bold"
        dense
        type="warning"
        tile
        transition="scale-transition"
      >
        The Selection Percentage Total Must Equal 100
      </v-alert>
      <v-alert
        v-if="isEditing || newUnsavedPools.length > 0"
        class="mb-0 pt-1 pb-1"
        transition="scale-transition"
        type="info"
        border="left"
        elevation="2"
        colored-border
        dense
      >
        * Changes Made To The Table Have Not Been Saved
        <span v-if="newUnsavedPools.length > 0"
          ><br /><i>- New Unsaved Pool(s):</i> <b>{{ newUnsavedPools.join(", ") }}</b></span
        >
      </v-alert>
    </div>
  </div>
</template>

<script>
import BackfillPoolsEditSlot from "@/components/medusa/BackfillPoolsEditSlot";
import BackfillPoolsHistoryLog from "@/components/medusa/BackfillPoolsHistoryLog";
import { mapState, mapMutations } from "vuex";
export default {
  props: ["spotId", "poolId", "feedId"],
  components: {
    appBackfillPoolsEditSlot: BackfillPoolsEditSlot,
    appBackfillPoolsHistoryLog: BackfillPoolsHistoryLog
  },
  data: () => ({
    ids: [],
    headers: [
      {
        text: "Pool",
        align: "start",
        sortable: false,
        value: "name",
        width: "20%"
      },
      {
        text: "Selection Percentage",
        value: "percentage",
        sortable: false,
        width: "15%",
        align: "center"
      },
      {
        text: "Enabled",
        value: "enabled",
        sortable: false,
        width: "10%",
        align: "center"
      },
      {
        text: "Items",
        value: "items",
        sortable: false,
        width: "5%",
        align: "center"
      }
    ],
    isValid: true,
    lastEditedBy: [],
    submitting: false,
    timestamp: "",
    totalPercentage: 0
  }),
  created() {
    this.setIsEditing(false);
    /**
     * Make Table Editable if !Spot
     */
    if (this.$store.state.auth.user.rolename != "admin") {
      this.setReadOnlyPools(true);
    } else {
      this.setReadOnlyPools(false);
    }
    if (!this.spotId) {
      let editHeader = [
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%",
          align: "center"
        }
      ];
      Array.prototype.push.apply(this.headers, editHeader);
    } else {
      // Get index of header array of 'percentage' header
      let index = this.headers.findIndex((header) => header.value === "percentage");
      // Spliced instead of adding after check (like editHeader) to maintain correct order
      // todo: thought there was a way to set order, but unable to find now
      this.headers.splice(index, 1);
    }
    /**
     * Get Backfill Pools and sort by Percentage Descending
     */
    Promise.all([
      this.$store
        .dispatch("backfillPools/getBackfillPools", {
          spotId: this.spotId
        })
        .then((data) => {
          this.setPools(
            data.sort((a, b) =>
              Number(b.percentage) == Number(a.percentage)
                ? b.name < a.name
                  ? 1
                  : -1
                : Number(b.percentage) > Number(a.percentage)
                ? 1
                : -1
            )
          );
          this.calcTotalPercentage();
          //origPools holds the original state of the pools to be able to check against and log user activity
          this.$store.dispatch("backfillPools/resetOrigPools");
        })
        .finally(() => {
          this.getLastEditedBy();
          //If poolId is passed as a query param and it exists, open edit pool dialog
          if (this.poolId) {
            let id = this.poolId;
            let pool = this.pools[this.pools.findIndex((x) => x.pool_id == id)];

            if (this.poolId && pool) {
              this.setEditPool(true);
              this.editItem(pool);
            } else {
              this.$store.commit("sendAlert", {
                msg: `Pool Id ${this.poolId} Cannot Be Found`,
                color: "error",
                position: "top"
              });
            }
          }
        })
    ]);
  },
  computed: mapState({
    editPool: (state) => {
      return state.backfillPools.editPool;
    },
    editPercent: (state) => {
      return state.backfillPools.editPercent;
    },
    isEditing: (state) => {
      return state.backfillPools.isEditing;
    },
    loading: (state) => {
      return state.backfillPools.loading;
    },
    lockedPercent: (state) => {
      return state.backfillPools.lockedPercent;
    },
    newUnsavedPools: (state) => {
      return state.backfillPools.newUnsavedPools;
    },
    origPools: (state) => {
      return state.backfillPools.origPools;
    },
    pools: (state) => {
      return state.backfillPools.pools;
    },
    readOnlyPools: (state) => {
      return state.backfillPools.readOnlyPools;
    }
  }),
  methods: {
    ...mapMutations({
      addPool: "backfillPools/addPool",
      setIsEditing: "backfillPools/setIsEditing",
      setEditPool: "backfillPools/setEditPool",
      setEditPercent: "backfillPools/setEditPercent",
      setLockedPercent: "backfillPools/setLockedPercent",
      setPools: "backfillPools/setPools",
      setReadOnlyPools: "backfillPools/setReadOnlyPools"
    }),
    /**
     * On Edit, Adjust the next unlocked pool(s) based on changes made to edited pool
     */
    adjustEditedPercentage(editedIndex, editedItem) {
      let newPercent = editedItem.percentage;
      let origPercent = this.pools[editedIndex].percentage;
      let adjustedPercent = origPercent - newPercent;
      if (editedItem.enabled) {
        //Case When total percent isn't 100%
        if (this.totalPercentage != 100) {
          //If total % is less than 100% DONT autoadjust
          if (this.totalPercentage - adjustedPercent <= 100) {
            this.calcTotalPercentage();
            return;
          }
          //If total starts < 100 and the adjustment would set it > 100, cap the adjusted %
          else if (this.totalPercentage - adjustedPercent > 100) {
            let overflow = this.totalPercentage - adjustedPercent - 100;
            editedItem.percentage -= overflow;
            return;
          }
        }
        for (let i = editedIndex; i <= this.pools.length - 1; i++) {
          let nextPool = this.pools[i + 1];
          //When the last pool is adjusted, set the index to the first pool
          if (!nextPool) {
            i = -1;
            nextPool = this.pools[0];
          }
          if (nextPool.enabled && !nextPool.locked) {
            //Set new adjusted percentage to check against before data binding takes effect.
            let newPercent = nextPool.percentage + adjustedPercent;
            //Adjust previous unlocked pools percentage
            nextPool.percentage = nextPool.percentage + adjustedPercent;
            //If there's no % leftover, break
            if (newPercent >= 0) break;
            adjustedPercent = newPercent;
            this.calcTotalPercentage();
          }
        }
        this.calcTotalPercentage();
      }
    },
    /**
     * When Any Selection Percentages change, check the total and perform actions if < 100%
     */
    calcTotalPercentage() {
      this.totalPercentage = 0;
      let percentLocked = 0;
      for (var pool of this.pools) {
        if (pool.enabled) {
          if (pool.percentage < 0) pool.percentage = 0;
          this.totalPercentage += Number(pool.percentage);
        }
        if (pool.locked && pool.enabled) {
          percentLocked += Number(pool.percentage);
        }
      }
      this.setLockedPercent(percentLocked);
      this.isValid = this.totalPercentage == 100 ? true : false;
      return this.totalPercentage;
    },
    /**
     * When a pool is disabled, Adjust the next unlocked enabled pools based on the % of the disabled pool
     *
     * @param {object} item being enabled
     */
    disablePool(item) {
      if (this.readOnlyPools) return;
      if (!item.typeData || !(item.typeData.length > 0)) {
        item.enabled = false;
        return;
      }
      this.setIsEditing(true);
      item.enabled = false;
      let enabledCount = this.pools.filter((pool) => pool.enabled && !pool.locked);
      //If 0 enabled pools
      if (enabledCount.length == 0) {
        this.calcTotalPercentage();
        return;
      }
      //When Disabling
      if (!item.enabled && !this.spotId) {
        let disabledPercentage = item.percentage;
        let enabledIndex = this.pools.indexOf(item);
        for (let i = enabledIndex; i <= this.pools.length; i++) {
          let nextPool = this.pools[i + 1];
          //When the last pool is disabled, set the index to the first pool
          if (!nextPool) {
            i = -1;
            nextPool = this.pools[0];
          }
          if (nextPool.enabled && !nextPool.locked) {
            nextPool.percentage = nextPool.percentage + disabledPercentage;
            break;
          }
        }
      }
      this.calcTotalPercentage();
    },
    /**
     * Edit A Backfill Pool
     *
     * @param {object} item to be edited
     */
    editItem(item) {
      if (this.editPercent && item.locked) {
        alert("Pool Must Be Unlocked Before You Can Edit The Percentage");
        this.setEditPercent(false);
        return;
      }
      this.$store.dispatch("backfillPools/editBackfillPool", {
        index: this.pools.indexOf(item),
        item
      });
    },
    /**
     * When a pool is enabled, Adjust the next unlocked enabled pools based on the % of the enabled pool
     *
     * @param {object} item being enabled
     */
    enablePool(item) {
      if (this.readOnlyPools) return;
      if (!item.typeData || !(item.typeData.length > 0)) {
        alert("Either a Doc Id or Feed Url Must Be Added Before You Can Enable This Pool");
        item.enabled = false;
        return;
      }
      this.setIsEditing(true);
      item.enabled = true;
      //If total % + enabled pool < 100 return
      if (this.totalPercentage != 100 && item.percentage + this.totalPercentage <= 100) {
        this.calcTotalPercentage();
        return;
      }
      //If new enabled % would put total over 100, set it to the difference
      else if (this.totalPercentage != 100 && item.percentage + this.totalPercentage > 100) {
        item.percentage = 100 - this.totalPercentage;
        this.calcTotalPercentage();
        return;
      }
      //When Enabling
      if (item.enabled && !this.spotId) {
        let enabledPercentage = item.percentage;
        let enabledIndex = this.pools.indexOf(item);
        for (let i = enabledIndex; i <= this.pools.length; i++) {
          let nextPool = this.pools[i + 1];
          //When the last pool is enabled, set the index to the first pool
          if (!nextPool) {
            i = -1;
            nextPool = this.pools[0];
          }
          if (nextPool.enabled && !nextPool.locked) {
            let newPercent = enabledPercentage - nextPool.percentage;
            nextPool.percentage = nextPool.percentage - enabledPercentage;
            if (newPercent <= 0) break;
            else enabledPercentage = newPercent;
          }
        }
      }
      this.calcTotalPercentage();
    },
    /**
     * Get the last user that edited any pools
     */
    getLastEditedBy() {
      return new this.$axios.get("/medusa/pools/last_edited_by").then((res) => {
        this.lastEditedBy = res.data;
        this.timestamp = new Date(
          this.lastEditedBy?.timestamp.replace(/-/g, "/") + " GMT+8"
        ).toLocaleString();
      });
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    /**
     * Recalculate the total locked percentage
     */
    reCalculateLockedPercent(item) {
      this.setIsEditing(true);
      if (item.locked) {
        this.setLockedPercent(Number(this.lockedPercent + item.percentage));
      } else {
        this.setLockedPercent(Number(this.lockedPercent - item.percentage));
      }
    },
    /**
     * Action Save Called from BackfillPoolsEditSlot
     *
     * @param {int} editedIndex
     *
     * @param {object} editedItem
     */
    saveDialog(editedIndex, editedItem) {
      this.setIsEditing(true);
      //For updating a pool
      if (editedIndex > -1) {
        this.adjustEditedPercentage(editedIndex, editedItem);
        Object.assign(this.pools[editedIndex], editedItem);
      } // For adding a New Pool
      else {
        this.newUnsavedPools.push(editedItem.name);
        if (editedItem.type & (editedItem.typeData.length > 0)) editedItem.typeSet = true;
        editedItem.id = this.pools.length + 1;
        this.addPool(editedItem);
      }
      this.calcTotalPercentage();
    }
  },
  watch: {
    totalPercentage(percent) {
      //If Total % != 100, disable Save Pools Button
      if (percent != 100) this.isValid = false;
      else this.isValid = true;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep thead {
  background: rgba(0, 0, 0, 0.8);
  tr {
    th {
      color: white !important;
    }
  }
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 30px !important;
}
::v-deep tr:nth-of-type(even) {
  background-color: rgba(150, 150, 150, 0.2);
}
.centered-input input {
  text-align: center;
}
::v-deep .v-input--switch {
  max-width: 20px !important;
  margin: auto;
}
::v-deep .v-input__control > .v-input__slot:before {
  border: none !important;
}
::v-deep .v-input__slot {
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 10px;
  max-height: 10px !important;
  margin-bottom: 0 !important;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px !important;
}
::v-deep .v-data-table-header th {
  height: 40px !important;
}
::v-deep .v-data-table .v-data-table__mobile-row {
  height: initial;
  min-height: 68px;
}
.totalPercentage,
.userHistory {
  background: rgba(0, 0, 0, 0.8);
  color: white !important;
  font-weight: 400;
  text-shadow: 2px 2px 2px black;
}
.totalPercentage .v-input__slot {
  align-items: start;
  justify-content: left;
  font-size: 13px;
}
.disableColumn {
  color: rgba(0, 0, 0, 0.38);
}
.inline-edit-text {
  width: 250px;
}
.selectionPercentage {
  margin: auto !important;
}
[role="columnheader"] .v-icon.v-data-table-header__icon {
  color: white !important;
}
.disableSlider .v-slider .primary {
  background: rgba(0, 0, 0, 0.26) !important;
}
.disableSlider .v-slider .v-slider__thumb-label.primary {
  color: transparent !important;
  background: transparent !important;
}
::v-deep .v-slider__thumb-label {
  bottom: -4px !important;
}
.pointer:hover {
  cursor: pointer;
}
.userHistory .v-input__slot {
  align-items: end;
  justify-content: right;
  font-size: 13px;
}
.userHistory {
  border-left: 1px white solid;
}
</style>
